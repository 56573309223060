export const apps = [
    [
        "gemba",
        "https://apps.apple.com/us/app/ltwp/id1588644796",
        "https://play.google.com/store/apps/details?id=nl.webzites.hseq",
        "Ventolines Gemba",
        new URL("../resources/images/apps/gemba-icon.png?width=200", import.meta.url),
        [
            new URL("../resources/images/apps/gemba1.png?width=500", import.meta.url),
            new URL("../resources/images/apps/gemba2.png?width=500", import.meta.url),
            new URL("../resources/images/apps/gemba3.png?width=500", import.meta.url),
        ],
        null,
        "ventolines.nl/gemba",
        "This observation app has been developed by Appteam, combining hands-on experience with ICT expertise. In order to improve the level of safety and/or quality in a construction project, the receipt of many observations from the work floor is critical. Appteam set off to developing a smartphone app which allows the user to report an observation within seconds. Experience proved that the filing of many observations will provide the project management with the right focal areas for improvement.",

    ],
    [
        "ltwp",
        "https://apps.apple.com/us/app/ltwp/id1588644796",
        "https://play.google.com/store/apps/details?id=com.livemegawatt.laketurkana&hl=en_US&gl=US",
        "Lake Turkana Wind Power",
        new URL("../resources/images/apps/ltwp-icon.png?width=200", import.meta.url),
        [
            new URL("../resources/images/apps/ltwp1.png?width=500", import.meta.url),
            new URL("../resources/images/apps/ltwp2.png?width=500", import.meta.url),
        ],
        null,
        "kppafrica.com",
        "Note: LTWP can only be used with a Lake Turkana Wind Power issued user account.\n\nThe Lake Turkana wind farm is located in the northern part of Kenya, south east of Lake Turkana. The wind farm comprises 365 turbines (Vestas V52, 850 kW), with a total capacity of 310 MW, and provides clean and affordable energy to the Kenyan national grid (on average 15% of Kenya’s energy demand). As a result, the wind farm reduces Kenya’s dependency on diesel and heavy fuel power stations and makes an important contribution to Kenya’s carbon emission reduction. ",

    ],
    [
        "wpf",
        "https://apps.apple.com/us/app/windpark-fryslân/id1554927408",
        "https://play.google.com/store/apps/details?id=com.livemegawatt.wpf",
        "Windpark Fryslân",
        new URL("../resources/images/apps/wpf-icon.png?width=200", import.meta.url),
        [
            new URL("../resources/images/apps/wpf1.png?width=500", import.meta.url),
            new URL("../resources/images/apps/wpf2.png?width=500", import.meta.url),
            new URL("../resources/images/apps/wpf3.png?width=500", import.meta.url),
        ],
        null,
        "windparkfryslan.nl",
        "Windpark Fryslân is wereldwijd het grootste windpark in een binnenwater. Het windpark Fryslân bestaat uit 89 turbines van 4,3 megawatt (MW). Op jaarbasis produceert WPF zo’n 1,5 terawattuur* (1.500.000 megawattuur). Dit is ongeveer 1,2% van het Nederlandse elektriciteitsverbruik en dit komt overeen met het stroomverbruik van ongeveer 500.000 huishoudens. In 2021 is Windpark Fryslân operationeel.\n\nDe Windpark Fryslân App laat u op aansprekende wijze zien hoeveel stroom Windpark Fryslân opwekt, hoe hard het waait en hoeveel stroom er de afgelopen tijd is geproduceerd. Daarnaast bevat de app het laatste nieuws.",
    ],
    [
        "betuwewind",
        "https://apps.apple.com/nl/app/betuwewind/id1560571380",
        "https://play.google.com/store/apps/details?id=com.livemegawatt.betuwewind&hl=en_US&gl=US",
        "Betuwewind",
        new URL("../resources/images/apps/betuwewind-icon.png?width=200", import.meta.url),
        [
            new URL("../resources/images/apps/betuwewind1.png?width=500", import.meta.url),
            new URL("../resources/images/apps/betuwewind2.png?width=500", import.meta.url),
            new URL("../resources/images/apps/betuwewind3.png?width=500", import.meta.url),
        ],
        null,
        "betuwewind.nl",
        "Met deze app kun je de energieopbrengst van de windmolens van Betuwewind volgen. Zowel de huidige als historische opbrengst en welke bijdrage dat levert aan de totale energiebehoefte van West Betuwe. Benieuwd waarom een molen stilstaat? Ook dat vind je in deze app.\nNieuwsgierig? Je vind er ook nieuws van Betuwewind en je kunt vragen stellen.",

    ],
    [
        "krammer",
        "https://apps.apple.com/nl/app/windpark-krammer/id1378596338",
        "https://play.google.com/store/apps/details?id=com.livemegawatt.krammer",
        "Windpark Krammer",
        new URL("../resources/images/apps/krammer-icon.png?width=200", import.meta.url),
        [
            new URL("../resources/images/apps/krammer1.png?width=500", import.meta.url),
            new URL("../resources/images/apps/krammer2.png?width=500", import.meta.url),
            new URL("../resources/images/apps/krammer3.png?width=500", import.meta.url),
            // new URL("../resources/images/apps/krammer4.png?width=500", import.meta.url),
        ],
        null,
        "windparkkrammer.nl",
        "Houd met deze app de precieze productie van Windpark Krammer bij! U kunt de huidige en historische productie bekijken. Ook is te vinden welke turbines goed draaien en waarom er mogelijk eentje stil staat. En bent u obligatiehouder? Dan kunt u in deze app ook een rendementsvoorspelling vinden.",

    ],
    [
        "gemini",
        "https://apps.apple.com/nl/app/gemini-wind-park/id1229502025",
        "https://play.google.com/store/apps/details?id=com.livemegawatt.gemini",
        "Gemini Windpark",
        new URL("../resources/images/apps/gemini-icon.png?width=200", import.meta.url),
        [
            new URL("../resources/images/apps/gemini1.png?width=500", import.meta.url),
            new URL("../resources/images/apps/gemini2.png?width=500", import.meta.url),
            new URL("../resources/images/apps/gemini3.png?width=500", import.meta.url),
        ],
        "iphone8",
        "geminiwindpark.nl",
        "The Gemini Wind Park application allows you to view live production data and meteorological information of one of the world’s largest wind farms, located in the Dutch North Sea. Discover the actual and historical wind conditions and energy production of Gemini Wind Park and find information and frequently asked questions.\n\nGemini Wind Park offers the following features:\n ⁃ Actual energy production data\n ⁃ Historical production and wind data\n ⁃ Wind speed and direction\n ⁃ Wave height\n ⁃ CO2 savings\n ⁃ Information about Gemini Wind Park\n ⁃ Frequently Asked Questions\n ⁃ Contact details",

    ],
    [
        "spinderwind",
        "https://apps.apple.com/nl/app/spinderwind/id1512837956",
        "https://play.google.com/store/apps/details?id=com.livemegawatt.spinderwind&hl=en_US&gl=US",
        "Spinderwind",
        new URL("../resources/images/apps/spinderwind-icon.png?width=200", import.meta.url),
        [
            new URL("../resources/images/apps/spinderwind1.png?width=500", import.meta.url),
            new URL("../resources/images/apps/spinderwind2.png?width=500", import.meta.url),
            new URL("../resources/images/apps/spinderwind3.png?width=500", import.meta.url),
        ],
        null,
        "spinderwind.nl",
        "De app van Spinderwind geeft de actuele elektriciteitsproductie, status en weersomstandigheden van het windpark weer. Ook kunt u de historische productie van bijvoorbeeld gisteren of de vorige maand bekijken, en het laatste nieuws over Burgerwindpark De Spinder lezen.",

    ],
    [
        "waardpolder",
        "https://apps.apple.com/nl/app/windpark-waardpolder/id1503567802",
        "https://play.google.com/store/apps/details?id=com.livemegawatt.waardpolder",
        "Waardpolder",
        new URL("../resources/images/apps/waardpolder-icon.png?width=200", import.meta.url),
        [
            new URL("../resources/images/apps/waardpolder1.png?width=500", import.meta.url),
            new URL("../resources/images/apps/waardpolder2.png?width=500", import.meta.url),
            new URL("../resources/images/apps/waardpolder3.png?width=500", import.meta.url),
            // new URL("../resources/images/apps/waardpolder4.png?width=500", import.meta.url),
        ],
        null,
        "wp-energiek.nl",
        "De app van Windpark Waardpolder geeft de actuele elektriciteitsproductie, status en weersomstandigheden van het windpark weer. Ook kunt u de historische productie en windsnelheden van bijvoorbeeld gisteren of de vorige maand bekijken.",

    ],
    [
        "westermeerwind",
        "https://itunes.apple.com/nl/app/windpark-westermeerwind/id1116980908?mt=8",
        "https://play.google.com/store/apps/details?id=nl.ventolines.westermeerwind",
        "Westermeerwind",
        new URL("../resources/images/apps/westermeerwind-icon.png?width=200", import.meta.url),
        [
            new URL("../resources/images/apps/westermeerwind1.png?width=500", import.meta.url),
            new URL("../resources/images/apps/westermeerwind2.png?width=500", import.meta.url),
            new URL("../resources/images/apps/westermeerwind3.png?width=500", import.meta.url),
        ],
        null,
        "westermeerwind.nl",
        "De Westermeerwind App laat u op aansprekende wijze zien hoeveel stroom Windpark Westermeerwind opwekt, hoe hard het waait en hoeveel stroom er de afgelopen tijd is geproduceerd. Daarnaast bevat de app het laatste nieuws en antwoorden op veelgestelde vragen.\nWindpark Westermeerwind vormt samen met twee andere windparken Windpark Noordoostpolder en staat in het IJsselmeer.\n\nDe Westermeerwind App bevat onder meer: \n- Actuele productiegegevens\n- Historische productiegegevens\n- CO2-besparingscijfers \n- Twitterfeed\n- Webcam \n- Nieuws over het windpark\n- Informatie over het windpark \n- Veelgestelde vragen\n- Contactgegevens",

    ],
    [
        "fritzy",
        "https://apps.apple.com/nl/app/fritzy/id1467026469",
        "",
        "Fritzy",
        new URL("../resources/images/apps/fritzy-icon.png?width=200", import.meta.url),
        [
            new URL("../resources/images/apps/fritzy1.png?width=500", import.meta.url),
            new URL("../resources/images/apps/fritzy2.png?width=500", import.meta.url),
        ],
        null,
        "fritzy.com",
        "Find your favourite venues with Fritzy! Fritzy is currently invite-only – request your invite now through the app.",

    ],
    [
        "heart",
        "https://apps.apple.com/nl/app/heart/id1088974734",
        "",
        "#Heart",
        new URL("../resources/images/apps/heart-icon.png?width=200", import.meta.url),
        [
            new URL("../resources/images/apps/heart1.png?width=500", import.meta.url),
            new URL("../resources/images/apps/heart2.png?width=500", import.meta.url),
        ],
        "iphone8",
        null,
        "Using Apple HealthKit with a heart rate monitor (for example the Apple Watch)? Visualise your heart rate measurements during the day with #Heart!\n\n#Heart includes charts of your heart rate during the day, your average heart rate per day and a graph of your daily averages per week. An automatically calculated Heart Rate Training Zone shows how often your heart rate is in this Training Zone.",

    ],
    [
        "walk",
        "https://apps.apple.com/nl/app/walk/id1091006492",
        "",
        "#Walk",
        new URL("../resources/images/apps/walk-icon.png?width=200", import.meta.url),
        [
            new URL("../resources/images/apps/walk1.png?width=500", import.meta.url),
            new URL("../resources/images/apps/walk2.png?width=500", import.meta.url),
        ],
        "iphone8",
        null,
        "#Walk, see how you move.\n\nVisualise the distance you've covered in a variety of insightful ways: per day or per week, as distance in meters or as steps counted.\n\n#Walk uses the Apple Health App (HealthKit) and is compatible with external step or distance counters, such as the Apple Watch, FitBit or JawBone Up. When an external device is connected you can view both measurements. To view your movement over the course of a week, weekly graphs are included as well.",

    ],
    [
        "barometer",
        "https://apps.apple.com/nl/app/barometer-barograph/id930132585",
        "",
        "Barometer & Barograph",
        new URL("../resources/images/apps/barometer-icon.png?width=200", import.meta.url),
        [
            new URL("../resources/images/apps/barometer1.png?width=500", import.meta.url),
            new URL("../resources/images/apps/barometer2.jpg?width=500", import.meta.url),
            new URL("../resources/images/apps/barometer3.png?width=500", import.meta.url),
        ],
        "iphone8",
        null,
        "We've created the ultimate Barometer &amp; Barograph Application for your iPhone 6, iPhone 6 Plus or iPad Air 2. (NOTE: Only works with these devices, as other devices don't have a barometric sensor)\n\nEasily read the value of the internal atmospheric pressure sensor, and view the relative changes in a simple graph. With live, daily, weekly and monthly charts. Rotate your device to get a fullscreen graph!\n\nThe app also includes a widget, so all you have to do to view the current air pressure is swiping down to reveal the notification center!\n\nChoose between these atmospheric pressure units:\n- Hectopascal (hPa), equal to milibar (mbar)\n- Milimeter of mercury (mmHg), equal to Torr\n- Inch of mercury (inHg)\n- Pounds per square inch (psi)\n- Standard atmosphere (atm)\n\nThe GPS altitude is used to adjust the dial display and to show the normal air pressure for your current altitude!",

    ],
    [
        "timey",
        "https://apps.apple.com/nl/app/timey-lite-presentation-timer/id1155926904",
        "",
        "Timey",
        new URL("../resources/images/apps/timey-icon.png?width=200", import.meta.url),
        [
            new URL("../resources/images/apps/timey1.png?width=500", import.meta.url),
            new URL("../resources/images/apps/timey2.png?width=500", import.meta.url),
            new URL("../resources/images/apps/timey3.png?width=500", import.meta.url),
            new URL("../resources/images/apps/timey4.png?width=500", import.meta.url),
        ],
        "iphone8",
        null,
        "In search for the perfect free presentation timer? Look no further!\n\nDon't want ads? Try Timey: Professional Presentation Timer!\n\nTimey is an extremely simple app to just do one thing and do it good: keeping track of your talking time and notifying you when time is up. Timey does this in three elegant colour schemes, of which the black-and-white mode is ideal for darker presentation rooms. Even notifying you happens elegantly: with a flashing screen and a choice of subtle but clear audio signals.\n\nTimey - your perfect presentation partner",

    ],
    [
        "bike-weather",
        "https://apps.apple.com/nl/app/bike-weather/id1440655115",
        "",
        "Bike Weather",
        new URL("../resources/images/apps/bike-weather-icon.png?width=200", import.meta.url),
        [
            new URL("../resources/images/apps/bike-weather1.png?width=500", import.meta.url),
            new URL("../resources/images/apps/bike-weather2.png?width=500", import.meta.url),
        ],
        "iphone8",
        null,
        "Bike Weather is the easiest way to decide wether or not to ride today! Never again be surprised by a stormy or wet trip home.\n\nSimply set your location, the time of your first trip and the time of your return trip: Bike Weather shows the relevant weather forecasts and grades the conditions on a scale from 1 to 10.",

    ],
    [
        "simple-survey",
        "https://apps.apple.com/nl/app/simple-survey-collection/id1191845328",
        "",
        "Simple Survey",
        new URL("../resources/images/apps/simple-survey-icon.png?width=200", import.meta.url),
        [
            new URL("../resources/images/apps/simple-survey1.png?width=500", import.meta.url),
            new URL("../resources/images/apps/simple-survey2.png?width=500", import.meta.url),
            new URL("../resources/images/apps/simple-survey3.png?width=500", import.meta.url),
        ],
        "iphone8",
        null,
        "Verzamel eenvoudig e-mail adressen of contactgegevens met deze simpele en elegante app. Met Simple Survey kunnen antwoorden worden verzameld en opgeslagen. De app is beveiligd met een code en de resultaten kunnen gedeeld worden via e-mail of andere iOS apps.\n\nFeatures\n* Functioneel en mooi design\n* Resultaten beveiligd met een viercijferige code\n* Twee talen: Engels en Nederlands\n* Aanpasbare bedankt text\n\nPRO features\n* Verzamel complete contactgegevens: e-mail adres én naam\n* Exporteer gegevens naar lokale contacten"

    ],
];