export const software = [
    {
        language: "Sketch",
        icon: new URL("../resources/images/icons/sketch.png?width=60", import.meta.url),
    },
    {
        language: "Blender",
        icon: new URL("../resources/images/icons/blender.png?width=60", import.meta.url),
    },
    {
        language: "Houdini",
        icon: new URL("../resources/images/icons/houdini.png?width=60", import.meta.url),
    },
    {
        language: "Git",
        icon: new URL("../resources/images/icons/git.png?width=60", import.meta.url),
    },
    {
        language: "InDesign",
        icon: new URL("../resources/images/icons/indesign.png?width=60", import.meta.url),
    },
    {
        language: "Illustrator",
        icon: new URL("../resources/images/icons/illustrator.png?width=60", import.meta.url),
    },
    {
        language: "Premiere Pro",
        icon: new URL("../resources/images/icons/premiere.jpg?width=60", import.meta.url),
    },
    {
        language: "Tableau",
        icon: new URL("../resources/images/icons/tableau.png?width=60", import.meta.url),
    },
    {
        language: "WebStorm",
        icon: new URL("../resources/images/icons/webstorm.png?width=60", import.meta.url),
    },
    {
        language: "XCode",
        icon: new URL("../resources/images/icons/xcode.png?width=60", import.meta.url),
    },
    {
        language: "NodeJS",
        icon: new URL("../resources/images/icons/nodejs.png?width=60", import.meta.url),
    },
    {
        language: "Unity",
        icon: new URL("../resources/images/icons/unity.png?width=60", import.meta.url),
    },
    {
        language: "Firebase",
        icon: new URL("../resources/images/icons/firebase.png?width=60", import.meta.url),
    },
    {
        language: "Stripe",
        icon: new URL("../resources/images/icons/stripe.png?width=60", import.meta.url),
    },
    {
        language: "SendGrid",
        icon: new URL("../resources/images/icons/sendgrid.svg", import.meta.url),
    },
    {
        language: "Docker",
        icon: new URL("../resources/images/icons/docker.png?width=60", import.meta.url),
    },
    {
        language: "Headless linux",
    },
];
export const programmingLanguages = [
    {
        language: "JavaScript",
        icon: new URL("../resources/images/icons/javascript.png?width=60", import.meta.url),
    },
    {
        language: "TypeScript",
        icon: new URL("../resources/images/icons/typescript.png?width=60", import.meta.url),
    },
    {
        language: "Swift",
        icon: new URL("../resources/images/icons/swift.png?width=60", import.meta.url),
    },
    {
        language: "Dart",
        icon: new URL("../resources/images/icons/dart.png?width=60", import.meta.url),
    },
    {
        language: "Kotlin",
        icon: new URL("../resources/images/icons/kotlin.png?width=60", import.meta.url),
    },
    {
        language: "Java",
        icon: new URL("../resources/images/icons/java.png?width=60", import.meta.url),
    },
    {
        language: "C#",
        icon: new URL("../resources/images/icons/C-Sharp.png?width=60", import.meta.url),
    },
    {
        language: "Arduino",
        icon: new URL("../resources/images/icons/arduino.svg", import.meta.url),
    },
    {
        language: "Objective-C",
    },
    {
        language: "SQL",
    },
    {
        language: "PostgreSQL",
        icon: new URL("../resources/images/icons/postgresql.png?width=60", import.meta.url),
    },
    {
        language: "GraphQL",
        icon: new URL("../resources/images/icons/graphql.png?width=60", import.meta.url),
    },
    {
        language: "Flutter",
        icon: new URL("../resources/images/icons/flutter.png?width=60", import.meta.url),
    },
    {
        language: "React",
        icon: new URL("../resources/images/icons/react.png?width=60", import.meta.url),
    },
    {
        language: "(S)CSS",
        icon: new URL("../resources/images/icons/sass.png?width=60", import.meta.url),
    },
]