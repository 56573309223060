import {Card, CardContent, Container, Dialog, Grid} from "@mui/material";
import {useState} from "react";
import {apps} from "../data/apps";

// import images from '../resources/images/apps/*.png';

export function Apps() {

    const [selectedAppNr, setSelectedAppNr] = useState(0);
    let selectedApp = apps[selectedAppNr];

    return <>

        <Container maxWidth="lg">
            <Card elevation={3}>

                <CardContent>

                    <h1>App development</h1>
                    <p>
                        Based on a clients needs I have developed native apps in Swift and Kotlin (previously in
                        Objective-C and Java as well), or multi-platform apps using Kotlin. The apps below are using
                        Firebase, Parse or custom back-ends, often combined with NodeJS parts.
                    </p>
                    <p>
                        Some of the apps below were developed together with Marthijn Bontekoning.
                    </p>

                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <h1>Apps</h1>
                            <div className="apps">
                                {apps.map((app, nr) =>
                                    <div
                                        className={"app-container clickable " + (selectedAppNr === nr ? "selected" : "")}
                                        key={"app" + app[0]}
                                        onClick={() => setSelectedAppNr(nr)}>

                                        <div className="app-icon">
                                            <img src={app[4]}/>
                                            <div className={"app-name"}>{app[3]}</div>
                                        </div>

                                        <Dialog open={false}>
                                            {app[5] && app[5].map(i =>
                                                <div className={"app-screen iphone13pro " + (app[6] ? app[6] : "")}
                                                     key={"app" + app[0] + "image" + i}>
                                                    <img src={i} className="screenshot"/>
                                                </div>
                                            )}
                                        </Dialog>
                                    </div>)}
                            </div>
                        </Grid>
                        <Grid item xs={8} className={"app-details"}>
                            <h2>{selectedApp[3]}</h2>
                            {selectedApp[7] ?
                                <a href={"http://" + selectedApp[7]} target={"_blank"}>
                                    <h4>{selectedApp[7]}</h4>
                                </a> : <h4>&nbsp;</h4>}

                            <div className="badges">
                                {selectedApp[1] && selectedApp[1] !== "" &&
                                    <a href={selectedApp[1]} target={"_blank"}><img
                                        src={new URL("../resources/images/app-store-badge.png", import.meta.url)}
                                        className={"badge apple"}/></a>}
                                {selectedApp[2] && selectedApp[2] !== "" &&
                                    <a href={selectedApp[2]} target={"_blank"}><img
                                        src={new URL("../resources/images/google-play-badge.png", import.meta.url)}
                                        className={"badge google"}/></a>}
                            </div>

                            <p className="description">
                                {selectedApp[8]}
                            </p>

                            <br/>

                            {selectedApp[5] && selectedApp[5].map((url, i) =>
                                <div className={"app-screen iphone13pro " + (selectedApp[6] ? selectedApp[6] : "")}
                                     key={"app" + selectedApp[0] + "image" + i}>
                                    <img src={url} className="screenshot"/>
                                </div>
                            )}
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>

        </Container>
    </>;
}