import {Apps} from "./pages/apps";
import {Card, Container, createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import MenuBar from "./components/menu";
import {Routes, Route} from "react-router-dom";
import {Home} from "./pages/home";

const theme = createTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#00406E',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#00406E',
            // dark: will be calculated from palette.secondary.main,
        },
    },

    components: {
        MuiButtonBase: {
            defaultProps: {
                // The props to change the default for.
                disableRipple: true, // No more ripple!
            },
        },
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    textTransform: 'none',
                }
            }
        },
    }
});

export function App() {
    return <ThemeProvider theme={theme}>
        <CssBaseline/>
        <br/>

        <Container maxWidth="lg">
            <Card elevation={3}>
                <MenuBar />
            </Card>
        </Container>

        {/*<br/><br/>*/}

        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="apps" element={<Apps />} />
        </Routes>

        <br/><br/>

    </ThemeProvider>;
}