import {Avatar, Button, Card, CardContent, CardMedia, Chip, Container, Divider, Grid} from "@mui/material";
import Box from "@mui/material/Box";
import {programmingLanguages, software} from "../data/software";

const ProjectToolsSmall = ({tools}) => {
    const items = programmingLanguages.filter(a => tools.indexOf(a.language) > -1);
    return <Box direction="row" className="project-tools"
                sx={{display: "flex", flexWrap: "wrap", gap: "5px"}}>
        {items.map((l, i) =>
            <img src={l.icon} alt={l.language}/>
        )}
    </Box>;
}

export function Home() {
    return <>

        <Container maxWidth="lg">
            <Card elevation={3}>
                <CardContent>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3} sx={{textAlign: "center"}}>
                            <img src={new URL("../resources/images/photos/Jeppe.jpg?height=300", import.meta.url)}
                                 className={"profile-picture"}/>
                        </Grid>
                        <Grid item xs={12} md={9}>

                            <h1>Jeppe Bijker <span className="light" title="Master of Science">MSc</span></h1>
                            <h3><span
                                className="light">Entrepreneur, designer & software engineer building a better future.</span>
                            </h3>

                            <p>
                                We can shape our future. And we need to make sure it will be a sustainable and fair one.
                                With
                                Green Tickets I worked on building the travel planner of the future. To call for a
                                global shift
                                towards sustainable transport I organized Sail to the COP (the UN Climate Conference): a
                                sailing trip from Amsterdam to South America,
                                with a group of changemakers ready to build a fair and sustainable future of travel.
                            </p>
                            <p>
                                I'm a Social Entrepreneur, Design Engineer and experienced UX Designer & Software
                                Developer. I
                                love to build solutions for the problems of today and tomorrow using modern programming
                                languages and frameworks (e.g. iOS Swift, Flutter, React, NodeJS) combined with
                                human-centred design.
                            </p>
                            <p>
                                I love to:
                            </p>
                            <ul>
                                <li>
                                    <span className={"emphasis"}>Create</span> a better future.
                                    <p className={"smaller no-margin"}>
                                        That's why I worked on a sustainable future of travel with Green Tickets, and
                                        why I love to make apps that give insights in renewable energy production.
                                    </p>
                                </li>
                                <li>
                                    <span className={"emphasis"}>Organize</span> to create that future together.
                                    <p className={"smaller no-margin"}>
                                        <em>Sail to the COP</em> and <em>Rail to the COP</em> were not just about
                                        bringing young people to the places where there futures are decided, but about getting
                                        there together, learning from each other and building a movement.
                                    </p>
                                </li>
                                <li>
                                    <span className={"emphasis"}>Code</span> to bring it to life.
                                    <p className={"smaller no-margin"}>
                                        Designing and coding are tools with which I can both rapidly prototype and build a
                                        scalable product. It's a creative process in itself and an extremely powerful tool to drive change.
                                    </p>
                                </li>
                            </ul>
                            <div className={"screen"}>
                                <p className="accent">
                                    Currently looking for opportunities in New York City.
                                </p>
                                <Box direction="row"
                                     sx={{display: "flex", flexWrap: "wrap", gap: "10px"}}>
                                    <Button href="mailto:contact@jeppebijker.com
                        " target="_blank" rel="noreferrer"
                                            variant="outlined">
                                        contact@jeppebijker.com
                                    </Button>

                                    <Button href="tel:0031633149435
                        " target="_blank" rel="noreferrer"
                                            variant="outlined">
                                        +31 6 33 14 94 35 (NL, WhatsApp, Signal)
                                    </Button>

                                    <Button href="tel:0019173466468
                        " target="_blank" rel="noreferrer"
                                            variant="outlined">
                                        +1 (917) 346-6468 (US)
                                    </Button>

                                    <Button href="https://www.linkedin.com/in/jeppebijker" target="_blank"
                                            rel="noreferrer"
                                            variant="outlined">
                                        LinkedIn
                                    </Button>

                                    <Button href="https://www.instagram.com/jeppebijker" target="_blank"
                                            rel="noreferrer"
                                            variant="outlined">
                                        Instagram
                                    </Button>
                                </Box>
                            </div>
                            <div className="print">
                                <table className={"contact"}>
                                    <tbody>
                                    <tr>
                                        <th style={{width: 70}}>
                                            E-mail
                                        </th>
                                        <td>
                                            contact@jeppebijker.com
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Phone
                                        </th>
                                        <td>
                                            +31 6 33 14 94 35 <span
                                            className={"grey"}>(NL, WhatsApp, Signal)</span><br/>
                                            +1 (917) 346-6468 <span className={"grey"}>(US)</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            LinkedIn
                                        </th>
                                        <td>
                                            www.linkedin.com/in/jeppebijker
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Instagram
                                        </th>
                                        <td>
                                            @jeppebijker
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Grid>
                    </Grid>


                    <br/>
                    <Divider variant="middle"/>
                    <br/>

                    <h2 className={"screen"}>Education</h2>

                    <Grid container spacing={2} className={"education"}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Card>
                                <CardContent>
                                    <h3>Master Design for Interaction</h3>
                                    <h4>Delft University of Technology (TUDelft)</h4>
                                    <div className={"period"}>2014 - 2016</div>
                                    <ul>
                                        <li>Graduation project at KLM (Passenger Services)</li>
                                        <li>Elective Cognitive Ergonomics</li>
                                        <li>Elective Experiencing Persuasive Environments</li>
                                        <li>Elective Service Design Process</li>
                                        <li>Elective Creative Facilitation</li>
                                        <li>One week entrepreneurial trip to Boston, MA, USA, organized by TU Delft
                                            incubator Yes!Delft.
                                        </li>
                                    </ul>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Card>
                                <CardContent>
                                    <h3>Bachelor Design for Engineering</h3>
                                    <h4>Delft University of Technology (TUDelft)</h4>
                                    <div className={"period"}>2010 - 2014</div>
                                    <ul>
                                        <li>Minor Software Design & Practice</li>
                                        <li>Elective Mechatronics</li>
                                        <li>Elective Towards Circular Product Design</li>
                                    </ul>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <br className={"screen"}/>

                    <Box direction="row" className="programming-languages"
                         sx={{display: "flex", flexWrap: "wrap", gap: "10px"}}>
                        <Chip label={"EU Drone licenses A1/A3 & A2"} variant={"outlined"}/>
                        <Chip label={"FAA Remote Pilot license (FAA Part 107)"} variant={"outlined"}/>
                    </Box>

                    <br className={"screen"}/>
                    <Divider variant="middle" className={"screen"}/>
                    <br className={"screen"}/>
                    <div className={"print-break"}/>

                    <Grid container spacing={2} className={"experience"}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    height="160"
                                    image={new URL("../resources/images/lake-turkana-phone.jpg?height=320", import.meta.url)}
                                />
                                <CardContent>
                                    <h3>Studio Bijker</h3>
                                    <h4>Owner</h4>
                                    <div className={"period"}>2008 - present</div>
                                    <a href="https://www.studiobijker.nl" className="block" target="_blank"
                                       rel="noreferrer">www.studiobijker.nl</a>
                                    <a href="https://www.livemegawatt.com" className="block" target="_blank"
                                       rel="noreferrer">www.livemegawatt.com</a>
                                    <p>
                                        Over the years I have developed <a href={"/apps"}>more than 15 apps</a> (mostly
                                        native iOS/Android and a few cross-platform Flutter apps)
                                        and many webapplications and back-ends for a lot of
                                        different clients, many together with co-founder Marthijn Bontekoning.
                                    </p>
                                    <p>
                                        <strong>Live Megawatt</strong> specializes in wind park applications: showing
                                        insights in renewable energy production.
                                    </p>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    height="160"
                                    image={new URL("../resources/images/photos/EDC-1.jpeg?height=320", import.meta.url)}
                                />
                                <CardContent>
                                    <h3>Drone Stories</h3>
                                    <h4>Head of Development</h4>
                                    <div className={"period"}>2020 - 2022 <span className={"light"}>(currently freelance)</span>
                                    </div>
                                    <a href="https://www.dronestories.com" className="block" target="_blank"
                                       rel="noreferrer">www.dronestories.com</a>
                                    <p>
                                        Initially tasked with software development (drone show design software and
                                        flightpath calculations for formation transitions) I quickly also took on show
                                        production. As Operational Manager and Pilot I worked on drone shows with up to
                                        600 drones in the United States, Puerto Rico, Italy, Montenegro, Greece and The
                                        Netherlands.
                                    </p>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    height="160"
                                    image={new URL("../resources/images/photos/DSC05763.jpg?height=320", import.meta.url)}
                                />
                                <CardContent>
                                    <h3>Youth for Sustainable Travel</h3>
                                    <h4>Co-founder, Secretary</h4>
                                    <div className={"period"}>2019 - 2021</div>
                                    <a href="http://www.youthforsustainabletravel.org"
                                       className="block">www.youthforsustainabletravel.org</a>
                                    <a href="http://www.sailtothecop.com" className="block" target="_blank"
                                       rel="noreferrer">www.sailtothecop.com</a>
                                    <p>
                                        The first project <strong>Sail to the COP</strong> saw me and 35 others sail
                                        accross the Atlantic Ocean from Amsterdam to South America, heading to the UN
                                        climate conference COP (Conference of Parties) 2019.
                                    </p>
                                    <p>
                                        With Sail to the COP I gained a lot of experience in setting up an organization
                                        and partnerships.
                                        I also designed the logo and website and created the website and fundraising
                                        process.
                                    </p>
                                    <p>
                                        2021 saw the second project: <strong>Rail to the COP</strong>, a chartered train
                                        from Amsterdam to Glasgow.
                                    </p>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    height="160"
                                    image={new URL("../resources/images/websites/greentickets.jpg?height=320", import.meta.url)}
                                />
                                <CardContent>
                                    {/*<Box direction="row" className="software"*/}
                                    {/*     sx={{display: "flex", flexWrap: "wrap", gap: "10px", justifyContent: "space-between"}}>*/}
                                    {/*    <div>*/}
                                            <h3>Green Tickets</h3>
                                            <h4>Co-founder</h4>
                                            <div className={"period"}>2018 - 2020 <span
                                                className={"light"}>(currently on hold)</span></div>
                                            <a href="https://www.greentickets.app"
                                               className="block">www.greentickets.app</a>
                                    {/*    </div>*/}
                                    {/*    <div>*/}
                                    {/*        <ProjectToolsSmall tools={["JavaScript", "React", "PostgreSQL"]}/>*/}
                                    {/*    </div>*/}
                                    {/*</Box>*/}
                                    <p>
                                        Green Tickets is building the European travel planner of the future: showing all
                                        available options including flights, trains, buses and ferries. For every option
                                        both the price, duration and climate impact are shown to find the best way of
                                        travel.
                                    </p>
                                    <p>
                                        After building an MVP, setting up a few partnerships and getting quite some
                                        positive media attention, the project has been put on hold because of technical
                                        challenges and a lack of available integration options with transport providers.
                                    </p>
                                    <p>
                                        With Green Tickets I’ve gained a lot of knowledge on mobility, carbon
                                        accounting, GTFS,
                                        graph theory, postgres databases and React.
                                    </p>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4} className={"screen"}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    height="160"
                                    image={new URL("../resources/images/websites/bu130.nl.png?height=320", import.meta.url)}
                                />
                                <CardContent>
                                    <h3>Stichting tot behoud van de BU 130</h3>
                                    <h4>Treasurer</h4>
                                    <div className={"period"}>2013 - 2016</div>
                                    <a href="https://www.bu130.nl"
                                       className="block">www.bu130.nl</a>
                                    <p>
                                        During my studies I have been treasurer of a foundation for the preservation and
                                        exploitation of a 140 year old wooden Botter, a typical Dutch fishing ship. With
                                        a group of students, the ship is maintained, restored and used almost every
                                        weekend.
                                    </p>
                                    <p>
                                        I have also designed and developed the website bu130.nl.
                                    </p>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>

                    <br/>
                    <Divider variant="middle" className={"screen"}/>
                    <br/>

                    {/*<h3>Programming Languages & Frameworks</h3><br/>*/
                    }


                    <h3>Programming Languages & Frameworks</h3>
                    <br/>
                    <Box direction="row" className="programming-languages"
                         sx={{display: "flex", flexWrap: "wrap", gap: "10px"}}>
                        {programmingLanguages.map((l, i) =>
                            <Chip key={"pi" + i} label={l.language} variant={"outlined"}
                                  avatar={l.icon && <Avatar src={l.icon}/>}/>
                        )}
                    </Box>

                    <br/>
                    <br/>

                    <h3>Software & Tools</h3>
                    <br/>
                    <Box direction="row" className="software" sx={{display: "flex", flexWrap: "wrap", gap: "10px"}}>
                        {software.map((l, i) =>
                            <Chip key={"pi" + i} label={l.language} variant={"outlined"}
                                  avatar={l.icon && <Avatar src={l.icon}/>}/>
                        )}
                    </Box>

                </CardContent>
            </Card>

        </Container>
    </>
        ;
}